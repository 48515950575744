import React, { Component } from "react"
import Layout from "../components/layout"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import mail from "../images/mail.svg"
import padlockClosed from "../images/padlock-closed.svg"
import axios from "axios"
import { ALERT_MSG_ERROR, ALERT_MSG_INFO, AUTH_TOKEN_KEY, translate, URL_ASK_RECOVERY_LINK, URL_LOGIN } from "../utils"
import ModalAlert from "../components/modalAlert"

class Recover extends Component{
  state = {
    email: '',
    showAlert: false,
    alertTitle: '',
    alertMessage: '',
  }

  submitForm = async (e) => {
    e.preventDefault();
    const data = {
      email: this.state.email
    }
    axios.post(URL_ASK_RECOVERY_LINK,data)
      .then(res => {
        this.setState({
          showAlert: true,
          alertTitle: ALERT_MSG_INFO,
          alertMessage: translate('an email will be sent to you in a few minutes with a recovery link'),
        })
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data);
          this.setState({
            showAlert: true,
            alertTitle: ALERT_MSG_ERROR,
            alertMessage: err.response.data.message,
          })
        }
        console.log(err)
      });
  }

  //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  render() {
    const {email,showAlert,alertMessage,alertTitle} = this.state;

    return (
      <Layout withBg>
        <div className="formWrapper">
          <Container>
            <Row className="justify-content-center">
              <Col md="12" lg="6" xl="4">
                <div className="text-left">
                  <h1 className="mb-3 h1-signupin-yellow">{translate('mijn wachtwoord herstellen')}</h1>
                  <p>{translate('voer uw e-mailadres in om een ​​herstellink te ontvangen')+"."}</p>

                  <Form onSubmit={e => this.submitForm(e)}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          <img
                            width={16}
                            height={8}
                            className="img-fluid"
                            src={mail}
                            alt=""
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        value={email}
                        onChange={(e) => this.setState({email: e.target.value})}
                        placeholder="E-mail"
                        aria-label=""
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <Button variant="success" type="submit" className="button-yellow">
                        {translate('versturen')}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {(showAlert)?
          <ModalAlert
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            closeModal={() => this.setState({showAlert: false})}
          />:null
        }
      </Layout>
    )
  }
}

export default Recover
